@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Form Inputs */
  .form-control {
    @apply block w-full shadow-sm border-gray-300 rounded-md mt-1;
  }

  .form-control.invalid {
    @apply border-red-500;
  }

  .form-control:focus {
    @apply ring-default-500 border-default-500;
  }

  @screen sm {
    .form-control {
      @apply text-sm;
    }
  }

  .form-label {
    @apply block text-sm font-medium text-gray-700;
  }

  .invalid-feedback {
    @apply text-red-500 text-sm;
  }

  /* Buttons */
  .btn {
    @apply py-2 px-4;
    @apply border border-transparent rounded-md shadow-sm;
    @apply text-sm font-medium;
  }

  .btn.full {
    @apply flex justify-center w-full;
  }

  .btn.default {
    @apply text-white bg-default-500;
  }

  .btn.default:hover {
    @apply bg-default-600;
  }

  .btn.danger {
    @apply text-white bg-red-500;
  }

  .btn.danger:hover {
    @apply bg-red-600;
  }

  .btn.secondary {
    @apply bg-white border-gray-300 text-gray-700;
  }

  .btn.secondary:hover {
    @apply bg-gray-50;
  }
}
